<template>
	<screen class="steps-view" size="large">
		<div
			:style="{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column'
			}"
		>
			<h1>
				Updating a few things... You will be automatically redirected
				shortly.
			</h1>
			<p>
				If you are not automatically redirected, please click
				<router-link :to="{ name: 'steps' }"><a>here</a></router-link
				>.
			</p>
		</div>
	</screen>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions } from 'vuex'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import Collapsible from '../../ui/Collapsible'
import { getUrlParams } from '../../../utilities'

export default {
	name: 'update-steps-view',
	components: { Screen, UiButton, UiLabel, Collapsible, Datepicker },
	data() {
		return {
			isLoading: false,
			found: false,
			institution: cloneDeep(this.$store.state.institution.item)
		}
	},
	computed: {},
	methods: {
		...mapActions(['updateInstitution'])
	},
	mounted() {
		const params = getUrlParams(window.location.href)

		if (params.scheduled_call && params.scheduled_call === '1') {
			this.institution.scheduled_call = true
			this.updateInstitution(this.institution)
				.then(() => {
					this.isLoading = false
					window.location = window.location.origin + '/#/steps'
				})
				.catch(() => {
					this.$swal({
						title: 'Uh Oh',
						text:
							'We got our wires crossed. Your meeting is confirmed, but will not appear as a completed steps. Please continue with the process. Our team is working to fix this.',
						showCancelButton: false,
						allowOutsideClick: true,
						allowEscapeKey: false
					}).then(() => {
						window.location = window.location.origin + '/#/steps'
					})
					this.isLoading = false
				})
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.steps-view {
	.roundup-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
</style>
